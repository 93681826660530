


























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { WITHDRAWAL_PAGINATION } from '@/app/infrastructures/misc/Constants'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import controller, { WithdrawalParamsInterface } from '@/app/ui/controllers/WithdrawalsController'
import { Utils } from '@/app/infrastructures/misc'
import { Dictionary } from 'vue-router/types/router'
import { withdrawalStatuses, EnumStatusWithdrawal } from '@/app/infrastructures/misc/Constants/withdrawal'

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    DataTable,
    PaginationNav,
    Loading,
  },
})
export default class WithdrawalPage extends Vue {
  controller = controller
  filterOptions = [
    { label: 'Show All', value: 'SHOWALL' },
    { label: 'Request', value: EnumStatusWithdrawal.PENDING },
    { label: 'Approved', value: EnumStatusWithdrawal.APPROVED },
    { label: 'Failed', value: EnumStatusWithdrawal.FAILED },
    { label: 'Rejected', value: EnumStatusWithdrawal.REJECTED },
  ]
  sortOptions = [
    { label: 'Newest', value: 'NEWEST' },
    { label: 'Oldest', value: 'OLDEST' },
  ]
  parameters = {
    page: 1,
    perPage: WITHDRAWAL_PAGINATION,
    keyword: '',
    sortBy: this.sortOptions[0],
    filterBy: this.filterOptions[0],
  }

  headers = [
    'ID',
    'Customer Name',
    'Amount',
    'Bank Detail',
    'Phone Number',
    'Request Date',
    'Status'
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || WITHDRAWAL_PAGINATION,
        keyword: queries.keyword || '',
        sortBy:
          this.sortOptions.find((item) => item.value === queries.sortBy) ||
          this.sortOptions[0],
        filterBy:
          this.filterOptions.find((item) => item.value === queries.filterBy) ||
          this.filterOptions[0],
      }
    }
    this.fetchWithdrawalList()
  }

  private fetchWithdrawalList(resetPage = false) {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getWithdrawalList(this.params)
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  get params(): WithdrawalParamsInterface {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      filterBy: this.parameters.filterBy.value,
    }
  }

  @Watch('parameters.keyword')
  onKeywordChanged(val: string): void {
    val === '' && this.fetchWithdrawalList(true)
  }

  get withdrawalDataTable(): Array<string | number | undefined>[] {
    return controller.withdrawalData.map((withdrawal) => [
      withdrawal.withdrawalId,
      withdrawal.customerName,
      this.formatCurrency(<number>withdrawal.amount),
      `${withdrawal.bankId?.toUpperCase()}
       ${withdrawal.bankAccountNumber?.toUpperCase()}
       ${withdrawal.bankAccountOwner?.toUpperCase()}`,
      withdrawal.phoneNumber,
      this.formatDate(<string>withdrawal.createdAt),
      withdrawalStatuses.find((status) => status.value === withdrawal.withdrawalStatusId)?.label,
    ])
  }

  private formatDate(date: string) {
    return Utils.formatTimeZone(
      Utils.formatDate(date, 'DD MMM YYYY HH:mm Z')
    )
  }

  private formatCurrency(input: number) {
    return `Rp${Utils.currencyDigit(input)}`
  }
}
